import React from 'react'
import { Link } from 'gatsby'


export default ({ headings }) => (
    <div style={{gridArea: "toc"}}>
      <h2>Table of Contents</h2> 
      <ul>
        {headings.items
          .map(item => (
              <li key={item.title}>
                <Link to={item.url}>{item.title}</Link>
              </li>
            ))}
      </ul>
    </div>
  )

