import React from 'react';
// import { OutboundLink } from "gatsby-plugin-google-analytics";
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default (props) => (
    <article className="br2 ba b--yellow dark-gray mv4 w-100 w-80-m w-70-l mw9 center">
        <div className="flex flex-wrap">
            <img src={props.imgUrl} className="db w-100 w-50-m w-40-l br2 br--top" alt={props.title} loading="lazy" />
            <div className="pa2 ph3-ns pb3-ns w-100 w-50-m w-60-l">
                <div className="dt w-100 mt1">
                <div className="dtc">
                    <h1 className="f5 f3-ns mv0">{props.title}</h1>
                    <h4 className="f5 f7-ns mv0">{props.subtitle}</h4>
                </div>
                </div>
                <p className="f5 lh-copy measure mt2 mid-gray">
                {props.description}
                </p>
                <div className="flex flex-column">
                    <div><OutboundLink target='_blank' rel="noreferrer" className="f6 link dim br2 ph3 pv2 mb2 dib white bg-lightest-blue fr grow" href={props.link}>{props.price} from {props.linkFrom}</OutboundLink></div>
                    {props.linkFrom2 ?
                        <div><OutboundLink target='_blank' rel="noreferrer" className="f6 link dim br2 ph3 pv2 mb2 dib white bg-lightest-blue fr grow" href={props.link2}>{props.price2} from {props.linkFrom2}</OutboundLink></div> : "" }
                </div>
            </div>
        </div>
    </article>
  )
